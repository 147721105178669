import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "management",
    path: "accueil / nos métiers / management / Responsable technique d’agence"
  },
  title: "Responsable technique d’agence", 
	missions:[
		{
		  text: "Gérer les prestations d’assistance techniques et les collaborateurs qui y sont rattachés" 
		},
	],
	key_skills: [
		{
		  text: "Communiquer avec charisme et conviction (équipe, hiérarchie, clients, candidats)" 
		},
		{
		  text: "Être orienté business" 
		},
		{
		  text: "Analyser et comprendre" 
		},
		{
		  text: "Se positionner en leader" 
		},
		{
		  text: "Capacité à comprendre et relayer la politique RH." 
		},
	],
	prerequisites:[
		{
		  text: "Maitrise" 
		},
		{
		  text: "Capacité à encadrer dans un environnement matriciel" 
		},
		{
		  text: "Anglais opérationnel" 
		},
		{
		  text: "5 ans d’expérience en tant qu’administrateur système réseau confirmé ou équivalent." 
		},
	],
	activities:[
		{
		  text: "Développer les comptes dont il a la charge en lien avec le commerce" 
		},
		{
		  text: "Traduire la demande de mission en « fiche de poste »" 
		},
		{
		  text: "Rechercher une solution interne / externe en relation avec les équipes RH" 
		},
		{
		  text: "Proposer le candidat / collaborateur requis dans le cadre d’un besoin exprimé par le client / prospect" 
		},
		{
		  text: "Participer aux recrutements et à la validation des candidats / collaborateurs retenus" 
		},
		{
		  text: "Manager les collaborateurs placés sous sa responsabilité" 
		},
		{
		  text: "Garantir le suivi administratif" 
		},
		{
		  text: "Prendre en charge l’évaluation et l’évolution des collaborateurs" 
		}
	]
}
const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;